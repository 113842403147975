.bgLogin{
    padding-top: 1vh;
    width: 100%;
}
.login-text{
    text-align: right;
    width: 30%;
    padding: 2vw 0.6vw 0.2vw 7vw
}
.login-inputbox{
    padding: 2vw 2vw 0.2vw 0.6vw
}
.login-input{
    text-align: left;
    width: 70%;
    border: 1.3px solid #3C4077;
    border-radius: 4px;
    padding: 2px 5px;
}
.login-input:focus{
    text-align: left;
    width: 70%;
    border: 1.7px solid #3C4077;
    border-radius: 4px;
    padding: 2px 5px;
    outline: none;
}
#login-title{
    text-align: center;
    font-weight: bolder;
    font-size: clamp(20px, 2vw, 26px);
    padding: 15px 0 5px;
}
#login-footer{
    text-align: center;
    padding: 0.5vw 0 1.2vw;
}
#login-footer button{
    text-align: center;
    padding: 2px 16px 5px 16px;
    background-color: #3C4077;
    color: white;
    border: 1px solid #3C4077;
    border-radius: 4px;
    margin: 8px 0;
}
#login-footer button:hover{
    text-align: center;
    padding: 2px 16px 5px 16px;
    background-color: #171C56;
    color: white;
    border: 1px solid #171C56;
    border-radius: 4px;
    margin: 8px 0;
}
#login-footer button:active{
    text-align: center;
    padding: 2px 16px 5px 16px;
    background-color: #777CB9;
    color: white;
    border: 1px solid #3C4077;
    border-radius: 4px;
    margin: 8px 0;
}
table{
    width: 100%;
}
tr{
    height: 50px;
}
