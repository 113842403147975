body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
}
.container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
h1, h2 {
    color: #333;
}
p {
    margin: 10px 0;
}
ul {
    margin: 10px 0;
    padding-left: 20px;
}
.protocol-agree{
    display: flex;
    justify-content: center;
}
.protocol-agree button{
    margin: 0 10px 0 10px;
    cursor: pointer;
    text-align: center;
    padding: 2px 16px 5px 16px;
    background-color: #3498db;
    color: white;
    border: 1px solid #3498db;
    border-radius: 4px;
}