.bg{
    width: 100%;
}
.register-text{
    text-align: right;
    width: 30%;
    padding: 2vw 0.6vw 0.2vw 7vw
}
.register-inputbox{
    padding: 2vw 2vw 0.2vw 0.6vw
}
.register-input{
    text-align: left;
    width: 70%;
    border: 1.3px solid #3C4077;
    border-radius: 4px;
    padding: 2px 5px;
}
.register-input:focus{
    text-align: left;
    width: 70%;
    border: 1.7px solid #3C4077;
    border-radius: 4px;
    padding: 2px 5px;
    outline: none;
}
.username-warnning{
    margin-left: 20px;
    border-radius: 3px;
    box-shadow: 2px 2px 2px grey;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    position: absolute;
    color: red;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    user-select: none;
}
.username-warnning.show{
    opacity: 1;
    visibility: visible;
}
.username-warnning.hide{
    opacity: 0;
    visibility: hidden;
}
#register-title{
    text-align: center;
    font-weight: bolder;
    font-size: 28px;
    padding: 15px 0 5px;
}
#register-footer{
    text-align: center;
    padding: 1.2vw 0 1vw;
}
#register-footer span{
    text-align: center;
    padding: 0.2vw 0 1vw;
}
#register-footer button:disabled{
    text-align: center;
    padding: 2px 12px 2.5px 12px;
    background-color: darkgray;
    color: white;
    border: 1px solid darkgray;
    border-radius: 4px;
    margin: 16px 0;
}
#register-footer button{
    text-align: center;
    padding: 2px 12px 2.5px 12px;
    background-color: #3C4077;
    color: white;
    border: 1px solid #3C4077;
    border-radius: 4px;
    margin: 16px 0;
}
#register-footer button:hover{
    text-align: center;
    padding: 2px 12px 2.5px 12px;
    background-color: #171C56;
    color: white;
    border: 1px solid #171C56;
    border-radius: 4px;
    margin: 16px 0;
}
#register-footer button:active{
    text-align: center;
    padding: 2px 12px 2.5px 12px;
    background-color: #777CB9;
    color: white;
    border: 1px solid #3C4077;
    border-radius: 4px;
    margin: 16px 0;
}
table{
    width: 100%;
}
tr{
    height: 50px;
}
