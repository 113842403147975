@keyframes editPanelMoveIn {
    0%{
        transform: translate(-50%, -50%) translateY(100vh);
        opacity: 0;
    }
    100%{
        transform: translate(-50%, -50%) translateY(0px);
        opacity: 1;
    }
}

/* position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1000; 
    width: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(10px); */
.naviBar{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    background-color: rgba(255,255,255,0.75);
    backdrop-filter: blur(10px);
    transform: translate(0%,0%);
}
.inner{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0.2vh 2vw;
}
.logoBox{
    margin: "-5vw";
    width: 5.5vh;
}
.logo{
    width: 100%;
    height: 100%;
    padding-top: 0.5vh;
    object-fit: cover;
}
.webName{
    font-family: "Comic Sans MS", "Comic Sans", cursive;
    color: #0D283C;
    font-size: 2.5vh;
    font-weight: 800;
    padding: 0 1.5vh;
    margin: -1vh -0.2vw;
}
.links{
    display: block;
    line-height: 1;
    padding: 8px 12px;
    border-radius: var(--mantine-radius-sm);
    text-decoration: none;
    color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));
    font-size: var(--mantine-font-size-sm);
    font-weight: 500;
    cursor: pointer;
}
/* .links:hover{
    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
} */
.signPanel{
    width: 40vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    z-index: 1000;
    box-shadow: 8px 8px 7px rgb(0, 0, 0, 0.25);
    border-radius: 15px;
}
.signPanel.show{
    opacity: 1;
    visibility: visible;
    display: block;
    animation: editPanelMoveIn 0.5s ease-in-out;
}
.overlay{
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
}
