.FooterSimple_footer__KLTrv {
  margin-top: calc(7.5rem * var(--mantine-scale));
  border-top: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-gray-2);
}
  [data-mantine-color-scheme='dark'] .FooterSimple_footer__KLTrv {
  border-top: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-dark-5);
}

.FooterSimple_inner__oQ_JN {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: var(--mantine-spacing-xl);
  padding-bottom: var(--mantine-spacing-xl);
}

@media (max-width: 36em) {

.FooterSimple_inner__oQ_JN {
    flex-direction: column
}
  }

@media (max-width: 36em) {

.FooterSimple_links__hoveT {
    margin-top: var(--mantine-spacing-md)
}
  }

@keyframes Header_editPanelMoveIn__i__Jv {
    0%{
        transform: translate(-50%, -50%) translateY(100vh);
        opacity: 0;
    }
    100%{
        transform: translate(-50%, -50%) translateY(0px);
        opacity: 1;
    }
}

/* position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1000; 
    width: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(10px); */
.Header_naviBar__2fhIE{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    background-color: rgba(255,255,255,0.75);
    backdrop-filter: blur(10px);
    transform: translate(0%,0%);
}
.Header_inner__qyQv8{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0.2vh 2vw;
}
.Header_logoBox___Xa0J{
    margin: "-5vw";
    width: 5.5vh;
}
.Header_logo__WjFmy{
    width: 100%;
    height: 100%;
    padding-top: 0.5vh;
    object-fit: cover;
}
.Header_webName__8OTw1{
    font-family: "Comic Sans MS", "Comic Sans", cursive;
    color: #0D283C;
    font-size: 2.5vh;
    font-weight: 800;
    padding: 0 1.5vh;
    margin: -1vh -0.2vw;
}
.Header_links__4H_ZY{
    display: block;
    line-height: 1;
    padding: 8px 12px;
    border-radius: var(--mantine-radius-sm);
    text-decoration: none;
    color: var(--mantine-color-gray-7);
}
[data-mantine-color-scheme='dark'] .Header_links__4H_ZY {
    color: var(--mantine-color-dark-0);
}
.Header_links__4H_ZY {
    font-size: var(--mantine-font-size-sm);
    font-weight: 500;
    cursor: pointer;
}
/* .links:hover{
    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
} */
.Header_signPanel__fZjYq{
    width: 40vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    z-index: 1000;
    box-shadow: 8px 8px 7px rgb(0, 0, 0, 0.25);
    border-radius: 15px;
}
.Header_signPanel__fZjYq.Header_show__n_Buz{
    opacity: 1;
    visibility: visible;
    display: block;
    animation: Header_editPanelMoveIn__i__Jv 0.5s ease-in-out;
}
.Header_overlay__vuUZg{
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
}

.LanguageSwitcher_buttonBox__4Gp9e{
    width: 10vw;
    height: 4vh;
    border-radius: 8px;
    outline: 1px solid lightgray;
}
.LanguageSwitcher_control__mHZqU {
    width: 10vw;
    height: 4vh;
    /* z-index: 10000; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
    border-radius: var(--mantine-radius-md);
    border: 1px solid darkgrest(var(--mantine-color-gray-2), var(--mantine-color-dark-6));
    transition: background-color 150ms ease;
    background-color: var(--mantine-color-white);
  }
[data-mantine-color-scheme='dark'] .LanguageSwitcher_control__mHZqU{
    background-color: var(--mantine-color-dark-6);
}
.LanguageSwitcher_control__mHZqU[data-expanded] {
      background-color: var(--mantine-color-gray-0);
    }
[data-mantine-color-scheme='dark'] .LanguageSwitcher_control__mHZqU[data-expanded]{
      background-color: var(--mantine-color-dark-5);
}
.LanguageSwitcher_control__mHZqU:hover {
      background-color: var(--mantine-color-gray-0);
    }
[data-mantine-color-scheme='dark'] .LanguageSwitcher_control__mHZqU:hover{
      background-color: var(--mantine-color-dark-5);
}
  
.LanguageSwitcher_circle___5ety{
    margin-left: 0.5vw;
}

.LanguageSwitcher_label__n583D {
    position: fixed;
    /* z-index: 20000; */
    margin-top: -0.3vh;
    padding-left: 2.5vw;
    font-weight: 500;
    font-size: var(--mantine-font-size-sm);
  }
  
.LanguageSwitcher_icon__teuIW {
    position: "bottom-start";
    /* z-index: 100000; */
    margin-right: 0.5vw;
    transition: transform 150ms ease;
    transform: rotate(0deg);
  }
  
[data-expanded] .LanguageSwitcher_icon__teuIW {
      transform: rotate(180deg);
    }
.bgLogin{
    padding-top: 1vh;
    width: 100%;
}
.login-text{
    text-align: right;
    width: 30%;
    padding: 2vw 0.6vw 0.2vw 7vw
}
.login-inputbox{
    padding: 2vw 2vw 0.2vw 0.6vw
}
.login-input{
    text-align: left;
    width: 70%;
    border: 1.3px solid #3C4077;
    border-radius: 4px;
    padding: 2px 5px;
}
.login-input:focus{
    text-align: left;
    width: 70%;
    border: 1.7px solid #3C4077;
    border-radius: 4px;
    padding: 2px 5px;
    outline: none;
}
#login-title{
    text-align: center;
    font-weight: bolder;
    font-size: clamp(20px, 2vw, 26px);
    padding: 15px 0 5px;
}
#login-footer{
    text-align: center;
    padding: 0.5vw 0 1.2vw;
}
#login-footer button{
    text-align: center;
    padding: 2px 16px 5px 16px;
    background-color: #3C4077;
    color: white;
    border: 1px solid #3C4077;
    border-radius: 4px;
    margin: 8px 0;
}
#login-footer button:hover{
    text-align: center;
    padding: 2px 16px 5px 16px;
    background-color: #171C56;
    color: white;
    border: 1px solid #171C56;
    border-radius: 4px;
    margin: 8px 0;
}
#login-footer button:active{
    text-align: center;
    padding: 2px 16px 5px 16px;
    background-color: #777CB9;
    color: white;
    border: 1px solid #3C4077;
    border-radius: 4px;
    margin: 8px 0;
}
table{
    width: 100%;
}
tr{
    height: 50px;
}

.bg{
    width: 100%;
}
.register-text{
    text-align: right;
    width: 30%;
    padding: 2vw 0.6vw 0.2vw 7vw
}
.register-inputbox{
    padding: 2vw 2vw 0.2vw 0.6vw
}
.register-input{
    text-align: left;
    width: 70%;
    border: 1.3px solid #3C4077;
    border-radius: 4px;
    padding: 2px 5px;
}
.register-input:focus{
    text-align: left;
    width: 70%;
    border: 1.7px solid #3C4077;
    border-radius: 4px;
    padding: 2px 5px;
    outline: none;
}
.username-warnning{
    margin-left: 20px;
    border-radius: 3px;
    box-shadow: 2px 2px 2px grey;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    position: absolute;
    color: red;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    user-select: none;
}
.username-warnning.show{
    opacity: 1;
    visibility: visible;
}
.username-warnning.hide{
    opacity: 0;
    visibility: hidden;
}
#register-title{
    text-align: center;
    font-weight: bolder;
    font-size: 28px;
    padding: 15px 0 5px;
}
#register-footer{
    text-align: center;
    padding: 1.2vw 0 1vw;
}
#register-footer span{
    text-align: center;
    padding: 0.2vw 0 1vw;
}
#register-footer button:disabled{
    text-align: center;
    padding: 2px 12px 2.5px 12px;
    background-color: darkgray;
    color: white;
    border: 1px solid darkgray;
    border-radius: 4px;
    margin: 16px 0;
}
#register-footer button{
    text-align: center;
    padding: 2px 12px 2.5px 12px;
    background-color: #3C4077;
    color: white;
    border: 1px solid #3C4077;
    border-radius: 4px;
    margin: 16px 0;
}
#register-footer button:hover{
    text-align: center;
    padding: 2px 12px 2.5px 12px;
    background-color: #171C56;
    color: white;
    border: 1px solid #171C56;
    border-radius: 4px;
    margin: 16px 0;
}
#register-footer button:active{
    text-align: center;
    padding: 2px 12px 2.5px 12px;
    background-color: #777CB9;
    color: white;
    border: 1px solid #3C4077;
    border-radius: 4px;
    margin: 16px 0;
}
table{
    width: 100%;
}
tr{
    height: 50px;
}

body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
}
.container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
h1, h2 {
    color: #333;
}
p {
    margin: 10px 0;
}
ul {
    margin: 10px 0;
    padding-left: 20px;
}
.protocol-agree{
    display: flex;
    justify-content: center;
}
.protocol-agree button{
    margin: 0 10px 0 10px;
    cursor: pointer;
    text-align: center;
    padding: 2px 16px 5px 16px;
    background-color: #3498db;
    color: white;
    border: 1px solid #3498db;
    border-radius: 4px;
}
.panel-bg{
    width: 100%;
    padding: 20px 45px 50px 45px;
}
.title{
    text-align: center;
}
.welcome{
    width: 100%;
    font-size: clamp(16px, 3vw, 30px);
    margin-right: 10px;
}
.site-name{
    width: 100%;
    font-weight: bolder;
    font-size: clamp(12px, 2vw, 24px);
}
.subtitle{
    width: 100%;
    text-align: center;
    font-size: clamp(10px, 1vw, 20px);
    color: #434884;
}
.login-opt{
    text-align: center;
}
.login{
    white-space: nowrap;
    text-align: center;
    border: none;
    color: #309AA8;
    text-decoration: underline;
    cursor: pointer;
}
.login-state.hidden{
    display: none;
}
.register-opt{
    text-align: center;
}
.register{
    white-space: nowrap;
    text-align: center;
    border: none;
    color: #309AA8;
    text-decoration: underline;
    cursor: pointer;
}
.register-state.hidden{
    display: none;
}
.placeholder{
    width: 100%;
}
.exit-button{
    width: 100%;
    display: flex;
}
#exitbutton{
    margin-left: auto;
    margin-right: 1.5vw;
    margin-top: 2vh;
    cursor: pointer;
}

