.panel-bg{
    width: 100%;
    padding: 20px 45px 50px 45px;
}
.title{
    text-align: center;
}
.welcome{
    width: 100%;
    font-size: clamp(16px, 3vw, 30px);
    margin-right: 10px;
}
.site-name{
    width: 100%;
    font-weight: bolder;
    font-size: clamp(12px, 2vw, 24px);
}
.subtitle{
    width: 100%;
    text-align: center;
    font-size: clamp(10px, 1vw, 20px);
    color: #434884;
}
.login-opt{
    text-align: center;
}
.login{
    white-space: nowrap;
    text-align: center;
    border: none;
    color: #309AA8;
    text-decoration: underline;
    cursor: pointer;
}
.login-state.hidden{
    display: none;
}
.register-opt{
    text-align: center;
}
.register{
    white-space: nowrap;
    text-align: center;
    border: none;
    color: #309AA8;
    text-decoration: underline;
    cursor: pointer;
}
.register-state.hidden{
    display: none;
}
.placeholder{
    width: 100%;
}
.exit-button{
    width: 100%;
    display: flex;
}
#exitbutton{
    margin-left: auto;
    margin-right: 1.5vw;
    margin-top: 2vh;
    cursor: pointer;
}
